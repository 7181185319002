@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;

  .icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .searchContainer {
    background-color: $main-blue-full;
    padding: 1rem;

    .title {
      @include XLnavyleft;
      margin-bottom: 1rem;
    }

    .subtitle {
      @include Swhitefullleft;
      margin-bottom: 0.5rem;
    }

    .companyPicker {
      max-width: 45rem;
      margin-bottom: 1.5rem;
    }

    .hello {
      @include Mwhiteleft;
    }
  }
}
