@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  background-color: $main-beige-full;
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
}

.topBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0;
  margin-bottom: 1.5rem;
}

.tabContainer {
  display: flex;
  overflow-y: scroll;
}

.loader {
  margin-top: 10rem;
  margin-left: 10rem;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 1rem;
  margin-left: 2rem;
}

.errorYellowLadder {
  margin-bottom: 1rem;

  svg {
    width: 13.188rem;
    height: 16rem;
    object-fit: contain;
  }
}

.errorTitle {
  @include Lnavyleft;
  margin-bottom: 0.25rem;
}

.errorDescription {
  @include Mgreenishleft;
}

.content {
  display: flex;
  flex-direction: row;
}
