@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
@import './GridCard/GridCard.module.scss';

.container {
  padding: 0rem 1rem 1rem 1rem;
  margin-top: 1.5rem;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
  }

  .title {
    @include Lnavyleft;
  }

  .assessments {
    display: grid;
    column-gap: 1.5rem;
  }

  .grid {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  }

  .formatIcon {
    height: 24px;
    width: 24px;
    margin-right: 0.5rem;
    path {
      fill: $main-white-full;
    }
  }
}

.icon {
  color: $main-white-full;
}

.trigger {
  width: 2.5rem;
  min-width: 0;
  border-width: 0;
  height: 2.5rem;
  border-radius: 8px;
  padding: 0.5rem;
}
