@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  padding: 1.5rem 3.5rem;
  overflow-y: scroll;
}

.routeNumber {
  @include Mnavyleft;
  width: 1.5rem;
  margin-left: -1.5rem;
  margin-bottom: 0.1rem;
  align-self: flex-end;
}

.route {
  display: flex;
  flex-direction: row;
  padding-bottom: 2rem;
}

.routeTitle {
  @include XLnavyleft;
}

.twoBtn {
  display: flex;
  flex-direction: row;
}

.validationButton {
  margin-right: 1rem;
}
