@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.separator {
  height: 1px;
  margin-bottom: 2rem;
  background-color: $contrast-grey-medium;
}

.title {
  @include XLnavyleft;
  margin-bottom: 1rem;
}

.comment {
  width: 100%;
  height: 10rem;
  margin-bottom: 2.5rem;
}
