@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

$card-height: 6.75rem;

.container {
  background-color: $main-beige-full;
  flex-direction: row;
  height: $card-height;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.container:hover {
  box-shadow: 0 0 0 2px $main-blue-full;
}

.description {
  @include XSgrey_mediumleft;
}

.firstPart {
  padding: 1rem 0.75rem;
  height: $card-height;
  border-radius: 8px 0 0 8px;
  display: flex;
  width: 46%;
  flex-direction: column;

  .date {
    @include XSgrey_mediumleft;
  }

  .companyName {
    @include Mblueleft;
    margin-top: 0.5rem;
    // Ellipsis on second line
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .companyAddress {
    @include XSgrey_mediumleft;
    margin-top: 0.5rem;
    // Ellipsis on one line
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.secondPart {
  padding: 1rem 0.75rem;
  height: $card-height;
  display: flex;
  width: 46%;
  flex-direction: column;

  .light {
    height: 1.75rem;
    @include Sgrey_mediumleft;
  }

  .contact {
    @include Mnavyleft;
    // Ellipsis on second line
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.thirdPart {
  padding-right: 0.75rem;
  height: $card-height;
  border-radius: 0 8px 8px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.separator {
  width: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: $contrast-grey-medium;
}

.icon {
  margin-right: 0.25rem;
}
