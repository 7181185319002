@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 2.5rem;
  width: 100%;
}

.label {
  @include Snavyleft;
  margin-right: 2rem;
  width: 50%;
  max-width: 40%;
}

.question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 2rem;
}

.toggle {
  margin-right: 1rem;
}

.note {
  margin-left: 1.5rem;
}

.separator {
  width: 1px;
  background-color: $contrast-grey-medium;
}
