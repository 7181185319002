@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.tabMenuContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @include Lblueleft;
  :first-child {
    margin-left: -0.5rem;
  }
}

.horizontalSeparator {
  @extend %separator;
  margin-top: 0.75rem;
}

.bottomBar {
  background-color: $main-navy-full;
  position: absolute;
  height: 0.25rem;
  transition: all 0.5s ease-out;
}
