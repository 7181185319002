@import-normalize "opinionated.css";

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-weight: normal;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: 'Graphik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}

button {
  background-color: transparent;
  border: none;

  &:active {
    opacity: 0.7;

    &[disabled] {
      opacity: 1;
    }
  }
}
