@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.gearButton {
  color: $main-white-medium;
  padding: 0.5rem;
  border-width: 0rem;
  height: 3rem;
  width: 3rem;
  min-width: 0rem;
}

.openGearButton {
  outline: none;
  background-color: $contrast-grey-lighter;
}

.gearIcon {
  color: $main-white-full;
}

.circleRandstad {
  margin-right: 0.5rem;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-medium;
}

.button {
  width: 110%;
  margin-right: -5%;
  margin-left: -5%;
  padding-right: 1rem;
  justify-content: start;
}
