@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  background-color: $main-beige-full;
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.content {
  display: flex;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.content::-webkit-scrollbar {
  display: none;
}

.topBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0;
  margin-bottom: 1.5rem;
}

.tabsContainer {
  flex: 0;
}
