@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.input {
  display: none;
}

.selectedText {
  color: $main-navy-full;
}

.tabItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
}
