@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.button {
  padding: 0.5rem;
  border-width: 0rem;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  min-width: 0rem;
  border-radius: 8px;
  svg {
    color: $main-blue-full;
  }
}

.openButton {
  outline: none;
  background-color: $contrast-grey-light;
  border-radius: 8px;
  svg {
    color: $main-blue-full;
  }
}

.menuIcon {
  width: 1.5rem;
  height: 1.5rem;
}
