@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.modalIcon {
  margin-top: -1.5rem;
}

.companyName {
  margin-bottom: 1rem;
  @include Snavyleft;
}

.gridContainer {
  display: grid;
  grid-column-gap: 1rem;
  justify-content: space-between;
  grid-row-gap: 1rem;
}

.gridItemLabel {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.5rem;
}

.gridItem {
  padding-top: 1rem;

  &:hover {
    padding-top: calc(1rem - 1px) !important;
  }
}

.contactPicker {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
