@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  max-width: 15.5rem;
  margin-top: 1.5rem;
}

.titleContainer {
  display: flex;
  flex: 0;
  flex-direction: column;
  padding: 1.5rem 0 1rem 0;
}

.linkContainer {
  display: flex;
  flex: 0;
  flex-direction: column;
}

.name {
  @include Snavyleft;
}

.title {
  @include XSgrey_mediumleft;
  margin-bottom: 0.5rem;
}

.link {
  @include Sblueleft;
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  width: 15.5rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  border-bottom: 1px solid $contrast-grey-medium;
  cursor: pointer;

  &.active {
    @include Snavyleft;
  }
}

.navNumber {
  min-width: 1.5rem;
}
