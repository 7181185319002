@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  min-height: 4.563rem;
  background-color: $main-blue-full;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.subContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.appLogo {
  height: 1.5rem;
  margin-bottom: 0.1rem;
  object-fit: contain;

  @media (max-width: 60em) {
    display: none;
  }
}

.greySeparator {
  width: 0.063rem;
  height: 1.688rem;
  margin: 0 1rem;
  background-color: $contrast-grey-medium;

  @media (max-width: 60em) {
    display: none;
  }
}

.navySeparator {
  width: 0.063rem;
  height: 1.688rem;
  margin: 0 1rem;
  background-color: $main-navy-full;

  @media (max-width: 35em) {
    display: none;
  }
}

.title {
  @include XLwhiteleft;

  @media (max-width: 35em) {
    display: none;
  }
}

.subtitle {
  @include XLnavyleft;

  @media (max-width: 35em) {
    display: none;
  }
}

.logo {
  height: 2rem;
  margin-bottom: 0.1rem;
  object-fit: contain;
  pointer-events: none;

  @media (max-width: 60em) {
    display: none;
  }
}
