@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  @extend %flex-center;
  width: 100%;
  height: 100%;

  background-color: $main_beige_full;
}

.modal {
  border: none;
  border-radius: 0.5rem;
  width: 20rem;

  background-color: $main-blue-full;

  flex-direction: column;
  position: relative;
  padding: 1.5rem;
}

.title {
  @include Lleft;
}

.description {
  @include Sleft;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}

.logo {
  width: 8rem;
  height: 8rem;
  object-fit: contain;
  margin-bottom: 2rem;
}

.randstad {
  width: 9.5rem;
  height: 1.5rem;
  object-fit: contain;
  margin-bottom: 4.5rem;
}

.button {
  width: 100%;
  border-radius: 0.25rem;
}
