@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 2.5rem;
  width: 100%;
}

.label {
  @include Snavyleft;
  margin-right: 2rem;
  width: 50%;
}

.question {
  margin-left: 2rem;
}
