@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

$upper-height: 7rem;
$lower-height: 6.75rem;
$card-height: calc(#{$lower-height} + #{$upper-height});
$column-gap: 1rem;

.cardContainer {
  background-color: $main-beige-full;
  flex-direction: column;
  height: $card-height;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.cardContainer:hover {
  box-shadow: 0 0 0 2px $main-blue-full;
}

.description {
  @include XSgrey_mediumleft;
}

.upperPart {
  padding: 1rem;
  height: $upper-height;
  border-radius: 8px 8px 0 0;

  .firstRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 1.75rem;
    justify-content: space-between;
    align-items: center;
  }

  .date {
    @include XSgrey_mediumleft;
  }

  .companyName {
    @include Mblueleft;
    margin-top: 0.5rem;
    // Ellipsis on one line
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .companyAddress {
    @include XSgrey_mediumleft;
    margin-top: 0.5rem;
    // Ellipsis on one line
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.separator {
  height: 1px;
  margin-left: 1rem;
  margin-right: 1.5rem;
  background-color: $contrast-grey-medium;
}
.lowerPart {
  padding: 1rem;
  height: $lower-height;
  border-radius: 0 0 8px 8px;

  .type {
    margin-bottom: 0.5rem;
    @include Sgrey_mediumleft;
  }

  .contact {
    @include Mnavyleft;
    height: 3rem;
    // Ellipsis on second line
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.icon {
  margin-right: 0.25rem;
}
